<script setup lang="ts">
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'
import type { ProductCareSideSlideProps } from '@design-system/components/Pdp/EcommerceBlock/PdpEcommerceBlockProductCareSideSlide.props'

defineProps<ProductCareSideSlideProps>()
</script>

<template>
  <OrganismsSidebarSlide
    :id="SIDEBAR_IDS.pdpEcommerceBlockProductCareSideSlide"
    :header-props="{ titleText: $ts('pdp.productCare') }"
  >
    <template #body>
      <div>
        <p class="text-medium-6">{{ productCare.title }}</p>
        <p class="text-light-6 mt-1">{{ productCare.description }}</p>
        <div class="mt-10 space-y-4">
          <div v-for="(i, idx) in productCare.items" :key="idx">
            <div class="flex items-center gap-4">
              <div class="h-6 w-6">
                <NuxtPicture
                  :src="i.image ?? ''"
                  class="object-contain"
                  provider="cloudinary"
                  sizes="sm:100vw md:50vw lg:33vw"
                  alt=""
                />
              </div>
              <p class="text-medium-6">{{ i.shortDescription }}</p>
            </div>
            <p class="text-light-6 mb-9 mt-1">
              {{ i.longDescription }}
            </p>
          </div>
        </div>
      </div>
    </template>
  </OrganismsSidebarSlide>
</template>
