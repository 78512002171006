<script setup lang="ts">
import type { PaypalWrapperProps } from './PaypalWrapper.props'

const props = withDefaults(defineProps<PaypalWrapperProps>(), {
  placement: 'product',
  styleTextSize: '10',
})

const appConfig = useAppConfig()
const { country } = useRouteHelper()

const supportedCountries = ['DE', 'ES', 'FR', 'GB', 'IT', 'US']

// https://developer.paypal.com/limited-release/sdk-pay-later-messaging-cross-border/#link-buyercountry
const paypalCountry = computed(() => country.toUpperCase())
const isCountrySupported = computed(() =>
  supportedCountries.includes(paypalCountry.value)
)

const enabled = computed(
  () =>
    (!appConfig.currentMarketSettings.paypalBNPLMaxPriceCents ||
      props.amount < appConfig.currentMarketSettings.paypalBNPLMaxPriceCents) &&
    isCountrySupported.value
)

// load script only if enabled
const script = enabled.value && usePaypalScript()
const loaded = computed(() => script && script.status.value === 'loaded')

const paypalAmount = computed(() => props.amount.toFixed(2))
</script>

<template>
  <div
    v-if="loaded"
    data-pp-message
    :data-pp-placement="placement"
    :data-pp-amount="paypalAmount"
    :data-pp-style-text-size="styleTextSize"
    :data-pp-buyercountry="paypalCountry"
  />
</template>
