import type { GASetupDatalayerParamItems } from '@integration-layer/schemas/GAEvents/dataLayerSetup'
import type { AlgoliaProductType } from '@integration-layer/modules/05.productServices/runtime/components/WrapperProductTile.props'
import type { ListResponse } from '@commercelayer/sdk'
import type { Sku } from '@commercelayer/sdk'

export const useGAEntityProductPage = (
  algoliaProduct: AlgoliaProductType,
  commercelayerProduct: ListResponse<Sku>
) => {
  const itemsEntity =
    useGAEntity<
      Omit<GASetupDatalayerParamItems, 'product_listSize' | 'product_listtype'>
    >('GA_DL_SETUP_ITEMS')

  const { category1, category2 } = extractCategories(
    algoliaProduct.hierarchicalCategories
  )

  const isAlgoliaAvailable = algoliaProduct.size?.some(item => item.avEcom)
  const isClAvailable = commercelayerProduct?.some(el => isInStock(el))

  const itemOutOfStock = isAlgoliaAvailable && isClAvailable ? 0 : 1

  itemsEntity.value = {
    item_category_level_1: category1,
    item_category_level_2: category2,
    itemOutOfStock,
    shop_category: 'item',
    storeAvailability: algoliaProduct.size?.some(item => item.avRet) ? 1 : 0,
  }
}
