<script setup lang="ts">
import type { PdpEcommerceBlockStoreDetailsProps } from '@design-system/components/Pdp/EcommerceBlock/PdpEcommerceBlockStoreDetails.props'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

const props = defineProps<PdpEcommerceBlockStoreDetailsProps>()

const formattedAddress = computed(() => {
  return `${props?.address?.line1}, ${props?.address?.postalCode} ${props?.address?.region} ${props?.address?.countryCode}`
})
</script>

<template>
  <div>
    <OrganismsSidebarSlide
      :id="SIDEBAR_IDS.pdpEcommerceBlockStoreDetails"
      :is-alpha-layer-transparent="true"
      :header-props="{
        titleText: $ts('pdp.storeDetails.label'),
        hasBackButton: true,
        hasCloseButton: false,
      }"
    >
      <template #body>
        <div class="flex flex-col">
          <p class="text-medium-4">{{ name ?? '' }}</p>
          <div class="mt-10">
            <p class="text-light-6">
              {{ $ts('pdp.storeDetails.address') }}
            </p>
            <p class="text-book-6 mt-2">
              {{ name ?? '' }} <br />
              {{ formattedAddress ?? '' }}
            </p>
          </div>
          <div class="mt-10">
            <p v-if="phone" class="text-light-6">
              {{ $ts('pdp.storeDetails.phone') }}
            </p>
            <MoleculesLinkWrapper
              anatomy="link"
              :to="`tel:${phone}`"
              class="mt-2"
            >
              <template #label>
                {{ phone }}
              </template>
            </MoleculesLinkWrapper>
          </div>
          <div v-if="!!emails?.length" class="mt-10">
            <p class="text-light-6">
              {{ $ts('pdp.storeDetails.email') }}
            </p>
            <MoleculesLinkWrapper
              anatomy="link"
              :to="`mailto:${emails[0]}`"
              class="mt-2"
            >
              <template #label>
                {{ emails[0] }}
              </template>
            </MoleculesLinkWrapper>
          </div>
          <div class="mt-10">
            <p class="text-light-6">
              {{ $ts('pdp.storeDetails.openingHours.label') }}
            </p>
            <div v-if="hours?.monday" class="flex flex-row justify-between">
              <p class="text-book-6 mt-2">
                {{ $ts('pdp.storeDetails.openingHours.monday') }}
              </p>
              <p
                v-if="!!hours?.monday?.openIntervals?.length"
                class="text-book-6 mt-2"
              >
                {{ hours?.monday?.openIntervals?.[0].start }} -
                {{ hours?.monday?.openIntervals[0].end }}
              </p>
              <p v-if="hours?.monday?.isClosed" class="text-book-6 mt-2">
                {{ $ts('checkout.pickup.closed') }}
              </p>
            </div>
            <div v-if="hours?.tuesday" class="flex flex-row justify-between">
              <p class="text-book-6 mt-2">
                {{ $ts('pdp.storeDetails.openingHours.tuesday') }}
              </p>
              <p
                v-if="!!hours.tuesday?.openIntervals?.length"
                class="text-book-6 mt-2"
              >
                {{ hours.tuesday.openIntervals[0].start }} -
                {{ hours.tuesday.openIntervals[0].end }}
              </p>
              <p v-if="hours.tuesday?.isClosed" class="text-book-6 mt-2">
                {{ $ts('checkout.pickup.closed') }}
              </p>
            </div>
            <div v-if="hours?.wednesday" class="flex flex-row justify-between">
              <p class="text-book-6 mt-2">
                {{ $ts('pdp.storeDetails.openingHours.wednesday') }}
              </p>
              <p
                v-if="!!hours.wednesday?.openIntervals?.length"
                class="text-book-6 mt-2"
              >
                {{ hours?.wednesday.openIntervals[0].start }} -
                {{ hours?.wednesday.openIntervals[0].end }}
              </p>
              <p v-if="hours.wednesday?.isClosed" class="text-book-6 mt-2">
                {{ $ts('checkout.pickup.closed') }}
              </p>
            </div>
            <div v-if="hours?.thursday" class="flex flex-row justify-between">
              <p class="text-book-6 mt-2">
                {{ $ts('pdp.storeDetails.openingHours.thursday') }}
              </p>
              <p
                v-if="!!hours.thursday?.openIntervals?.length"
                class="text-book-6 mt-2"
              >
                {{ hours?.thursday?.openIntervals[0].start }} -
                {{ hours?.thursday?.openIntervals[0].end }}
              </p>
              <p v-if="hours.thursday?.isClosed" class="text-book-6 mt-2">
                {{ $ts('checkout.pickup.closed') }}
              </p>
            </div>
            <div v-if="hours?.friday" class="flex flex-row justify-between">
              <p class="text-book-6 mt-2">
                {{ $ts('pdp.storeDetails.openingHours.friday') }}
              </p>
              <p
                v-if="!!hours.friday?.openIntervals?.length"
                class="text-book-6 mt-2"
              >
                {{ hours.friday.openIntervals[0].start }} -
                {{ hours.friday.openIntervals[0].end }}
              </p>
              <p v-if="hours.friday?.isClosed" class="text-book-6 mt-2">
                {{ $ts('checkout.pickup.closed') }}
              </p>
            </div>
            <div v-if="hours?.saturday" class="flex flex-row justify-between">
              <p class="text-book-6 mt-2">
                {{ $ts('pdp.storeDetails.openingHours.saturday') }}
              </p>
              <p
                v-if="!!hours.saturday?.openIntervals?.length"
                class="text-book-6 mt-2"
              >
                {{ hours.saturday.openIntervals[0].start }} -
                {{ hours.saturday.openIntervals[0].end }}
              </p>
              <p v-if="hours.saturday?.isClosed" class="text-book-6 mt-2">
                {{ $ts('checkout.pickup.closed') }}
              </p>
            </div>
            <div v-if="hours?.sunday" class="flex flex-row justify-between">
              <p class="text-book-6 mt-2">
                {{ $ts('pdp.storeDetails.openingHours.sunday') }}
              </p>
              <p
                v-if="!!hours.sunday?.openIntervals?.length"
                class="text-book-6 mt-2"
              >
                {{ hours.sunday.openIntervals[0].start }} -
                {{ hours.sunday.openIntervals[0].end }}
              </p>
              <p v-if="hours.sunday?.isClosed" class="text-book-6 mt-2">
                {{ $ts('checkout.pickup.closed') }}
              </p>
            </div>
          </div>
        </div>
      </template>
    </OrganismsSidebarSlide>
  </div>
</template>
