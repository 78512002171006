import type { z } from 'zod'
import { viewItemSchema } from '@integration-layer/schemas/GAEvents/viewItem'
import snakeCase from 'lodash/snakeCase'
import type { ListResponse } from '@commercelayer/sdk'
import type { Sku } from '@commercelayer/sdk'
import { productSchema } from '@integration-layer/.nuxt/armani/product-schema'

type ViewItem = z.infer<typeof viewItemSchema>
type AlgoliaProductType = z.infer<typeof productSchema>

export const useGAViewItemEvent = (
  algoliaProduct: AlgoliaProductType,
  clPricesAndStocks: ListResponse<Sku> | null,
  options?: { waitForDatalayerSetup: boolean }
) => {
  if (!import.meta.client) return
  const isAlgoliaProductValid = Object.keys(algoliaProduct).length > 0
  if (!isAlgoliaProductValid) return

  const { category1, category2, category4 } = extractCategories(
    algoliaProduct?.hierarchicalCategories
  )
  const { getCurrencySymbolFromCountryCode } = useCurrency()
  const { country } = useRouteHelper()

  const firstClSku = clPricesAndStocks?.[0]
  const skuPrice = clPricesAndStocks?.[0]?.prices?.[0]
  const amountFloat = skuPrice?.amount_float ?? algoliaProduct.FullPrice
  const discount =
    skuPrice?.compare_at_amount_float! > amountFloat
      ? percentageDiscount(skuPrice?.compare_at_amount_float!, amountFloat)
      : undefined

  const isProductOnCommercelayerDefined =
    clPricesAndStocks && clPricesAndStocks.length > 0

  const availableProduct =
    clPricesAndStocks?.find(el => el.stock_items?.[0]?.quantity ?? 0 > 0) ??
    clPricesAndStocks?.[0]
  const availableItem: ViewItem['ecommerce']['items'] = [
    {
      affiliation: 'armani.com',
      coupon: '',
      discount: stringPriceToNumber(discount ?? '0'),
      index: 1,
      item_availability: availableProduct
        ? isInStock(availableProduct)
          ? 1
          : 0
        : 0,
      item_brand:
        algoliaProduct.Brand.toLowerCase() === 'ea7'
          ? 'EA7'
          : snakeCase(algoliaProduct.Brand ?? 'armani group'),
      item_category: snakeCase(category1),
      item_category2: snakeCase(category2),
      item_category4: snakeCase(category4),
      item_discount: discount ? 1 : 0,
      item_id: algoliaProduct.objectID ?? '',
      item_img: availableProduct?.image_url ?? '',
      item_list_id: 'category',
      item_list_name: snakeCase(`${algoliaProduct.Brand}-${category2}`),
      item_mfc_id: algoliaProduct.MFC ?? '',
      item_name: algoliaProduct.Name ?? '',
      item_reservable: 0,
      item_size: '',
      item_variant:
        algoliaProduct.MacroColorDesc?.split('_')?.[1] ??
        algoliaProduct.MacroColorDesc ??
        '',
      price: availableProduct?.prices?.[0].amount_float ?? 0,
      quantity: isProductOnCommercelayerDefined ? 1 : 0,
    },
  ]
  const currency = getCurrencySymbolFromCountryCode(country)
  const event: ViewItem = {
    event: 'view_item',
    eventID: '005',
    ecommerce: {
      currency: currency?.code ?? 'EUR',
      view_type: 'product_page',
      value: firstClSku?.prices?.[0].amount_float ?? 0,
      items: availableItem,
    },
  }
  useGASendEvent(viewItemSchema, event, {
    clearEcommerce: true,
    waitForDatalayerSetup: options?.waitForDatalayerSetup,
  })
}
