import type { ProductFeatures } from '@integration-layer/ampliance/schemas/product-features-schema.localized'

export const useProductFeaturesList = (deliveryKey: string) => {
  const localePath = useLocalePath()
  const route = useRoute()
  const { country } = useRouteHelper()

  const productFeaturesList: Ref<ProductFeatures | null> = useState(
    'productFeaturesList',
    () => null
  )

  const deliveryKeyComp = computed(() => deliveryKey)

  const fetchProductFeatures = async (key: string) => {
    const query = {
      locale: localePath(''),
      vse: route.query.vse,
      contentId: route.query.contentId,
      deliveryKey: key,
    }

    try {
      return await $fetch('/api/cms/getProductFeaturesList', { query })
    } catch (error) {
      console.error(`Error fetching product features for key: ${key}`, error)
      throw error // rethrow the error for the caller to handle
    }
  }

  const loadProductFeatures = async () => {
    // try {
    //   const response = await fetchProductFeatures(`${deliveryKey}-${country}`)
    //   productFeaturesList.value = response.content
    // } catch {
    try {
      const fallbackResponse = await fetchProductFeatures(deliveryKey)
      productFeaturesList.value = fallbackResponse.content
    } catch (error) {
      productFeaturesList.value = null
      console.error('Failed to load product features:', error)
    }
    // }
  }

  watch(
    deliveryKeyComp,
    () => {
      loadProductFeatures()
    },
    { immediate: true }
  )

  return {
    productFeaturesList,
  }
}
