<script setup lang="ts">
import type { MoleculesAddToBagProps } from './MoleculesAddToBag.props'

const props = withDefaults(defineProps<MoleculesAddToBagProps>(), {
  anatomy: 'primary',
  square: false,
  disabled: false,
  outOfStock: false,
  selectedExtOutOfStock: false,
  selectedDefaultOrOneSizeOutOfStockCl: false,
  iconPosition: 'left',
  fullWidth: false,
  loading: false,
  success: false,
  fullHeight: false,
  iconFadeIn: false,
})
const emit = defineEmits(['click-handler'])
const { ts } = useI18n()
const clickHandler = () => {
  emit('click-handler')
}
// Computed property for label
const label = computed(() => {
  if (props.success) return ts('added')

  if (props.disabled) return ts('productCard.soldOut')

  if (props.square) return ts('addToCartPdp')

  return ts('addToCart')
})
</script>

<template>
  <AtomsCta
    :disabled="disabled"
    :anatomy="anatomy"
    icon-position="left"
    :icon-fade-in="false"
    :loading="loading"
    :square="square"
    :success="success"
    :full-width="fullWidth"
    :full-height="fullHeight"
    :aria-label="label"
    aria-haspopup="dialog"
    @click-handler="clickHandler"
  >
    <template #label>
      {{ label }}
    </template>
    <template #icon>
      <DefaultIconsCart aria-hidden="true" />
    </template>
    <template #secondaryIcon>
      <DefaultIconsAdd aria-hidden="true" />
    </template>
  </AtomsCta>
</template>
