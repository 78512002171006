<script lang="ts" setup>
import type { PdpEcommerceBlockProductFocusSideSlideProps } from '@design-system/components/Pdp/EcommerceBlock/PdpEcommerceBlockProductFocusSideSlide.props'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

defineProps<PdpEcommerceBlockProductFocusSideSlideProps>()

const { currentBrandTheme: brandTheme } = await useTheme()
const isDarkTheme = computed(() => brandTheme.value === 'emporio-armani')
</script>

<template>
  <OrganismsSidebarSlide
    :id="SIDEBAR_IDS.pdpEcommerceBlockProductFocusSideSlide"
    :header-props="{ titleText: $ts('pdp.productFocus') }"
  >
    <template #body>
      <div v-if="items && !!items.length">
        <div
          v-for="(item, i) in items"
          :key="`item-${i}`"
          :class="[
            'space-y-6 border-b py-8 first-of-type:border-t last-of-type:mb-8 lg:py-10 lg:last-of-type:mb-10',
            {
              'border-primitives-grey-300': isDarkTheme,
              'border-primitives-grey-100': !isDarkTheme,
            },
          ]"
        >
          <NuxtPicture
            provider="cloudinary"
            class="object-contain"
            :src="item?.image?.[isDarkTheme ? 'light' : 'dark']"
            :alt="item?.image?.alt"
            sizes="75px"
            :img-attrs="{ style: 'height:24px' }"
            placeholder
          />
          <UtilsMarkdown
            v-if="item?.description"
            class="text-light-6"
            :content="item.description"
          />
        </div>
      </div>
    </template>
  </OrganismsSidebarSlide>
</template>
