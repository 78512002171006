<script setup lang="ts">
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'
import type { PdpImage3dModalProps } from './PdpImage3dModal.props'

defineProps<PdpImage3dModalProps>()
</script>

<template>
  <OrganismsModal
    :id="SIDEBAR_IDS.pdp3dImage"
    class="z-[60]"
    :force-global-theme="true"
  >
    <template #body>
      <div class="bg-neutral-white h-dvh">
        <div class="flex h-full w-full justify-center">
          <PdpImage3dIframe
            class="mt-16 h-[calc(100%_-_4rem)]"
            :model="model ?? ''"
          />
        </div>
      </div>
    </template>
  </OrganismsModal>
</template>
