<script setup lang="ts">
import type { PdpEcommerceBlockProductNameProps } from './PdpEcommerceBlockProductName.props'
const props = defineProps<PdpEcommerceBlockProductNameProps>()
const MAX_LABEL_NUMBER = 3

const items = computed(() => {
  return props.tags?.slice(0, MAX_LABEL_NUMBER) ?? []
})

const { formatPriceByLocale } = useFormatPrice()
const { currentCurrency } = useCurrency()
</script>

<template>
  <div>
    <div
      class="gap-xxs lg:gap-sm text-primitives-black flex h-[var(--product-name-height)] flex-col justify-start lg:h-auto"
    >
      <div v-if="items.length > 0" class="gap-xs hidden lg:mt-0 lg:flex">
        <AtomsBadgeProductCart
          v-for="(tag, index) in items"
          :key="'pdp-badge-product-card-' + index"
          :text="tag"
        />
      </div>
      <component
        :is="isHeading ? 'h1' : 'span'"
        class="text-book-6 lg:text-book-5 order-1 lg:order-2"
      >
        {{ title.toUpperCase() }}
      </component>

      <div v-if="isShoppable" class="text-light-6 order-2 lg:order-3">
        <div class="flex gap-x-2">
          <span
            v-if="oldPrice"
            class="text-primitives-grey-200 text-light-strike-6 line-through"
          >
            {{ formatPriceByLocale(currentCurrency, oldPrice) }}
          </span>
          <span v-if="price" class="text-light-6">
            {{ formatPriceByLocale(currentCurrency, price) }}
          </span>
          <span v-if="discount" class="text-medium-6">{{ discount }}</span>
        </div>
      </div>
    </div>
    <div v-if="items.length > 0" class="gap-xs flex lg:hidden">
      <AtomsBadgeProductCart
        v-for="(tag, index) in items"
        :key="'pdp-badge-product-card-' + index"
        :text="tag"
      />
    </div>
  </div>
</template>

<style lang="scss">
:root {
  --product-name-height: 82px;
}
</style>
