<script setup lang="ts">
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

const { isDialogOpen } = useDialog(SIDEBAR_IDS.pdpInfoReturnItem)

const { data: infoShippingContent, execute } =
  await useInfosWithTitleItem('info-free-return')
watch(isDialogOpen, async value => {
  if (value) {
    await execute()
  }
})
</script>
<template>
  <OrganismsSidebarSlide
    :id="SIDEBAR_IDS.pdpInfoReturnItem"
    :header-props="{ titleText: infoShippingContent?.content?.title ?? '' }"
  >
    <template #body>
      <div class="flex flex-col gap-y-2">
        <UtilsMarkdown
          v-if="infoShippingContent?.content?.body"
          :content="infoShippingContent?.content?.body"
          class="text-light-6 mb-2"
          container="h3"
        />
      </div>
    </template>
  </OrganismsSidebarSlide>
</template>
