<script setup lang="ts">
import type { VisenzeRecommendationsProps } from './VisenzeRecommendations.props'

const props = defineProps<VisenzeRecommendationsProps>()

const { completeTheLook, similarProducts } = useVisenze()

const { data: products } = await useAsyncData(
  `product-visenze-${props.productSku}-${props.type}`,
  async () => {
    if (props.type === 'STL') {
      return await completeTheLook(props.productSku)
    }

    if (props.type === 'VSR') {
      return await similarProducts(props.productSku)
    }

    return []
  },
  {
    watch: [() => props.type],
    server: false,
  }
)

const productItemsDefaultSkuCodes = computed(() => {
  if (!products.value) return []
  return products.value
    .map((item: any) => getSKUfromSize(item.data?.size_list?.at(0) || ''))
    .filter(item => isNonNullable<string>(item))
})

const { skuPrices, fetchSkuPrices } = useProductTileClPrices()

// CL prices fetch only happens client side
onMounted(() => {
  fetchSkuPrices(productItemsDefaultSkuCodes.value)
})

const productsWithCommerceLayerPrices = computed(() => {
  if (!products.value) return []
  return products.value.map((product: any) => {
    const skuWithSize = getSKUfromSize(product.data?.size_list?.at(0) || '')
    return {
      ...product,
      clPrices: skuPrices.value?.[skuWithSize],
    }
  })
})
</script>
<template>
  <slot :products="productsWithCommerceLayerPrices" />
</template>
