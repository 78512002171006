<script setup lang="ts">
import type { PdpEcommerceBlockFitFinderProps } from '@design-system/components/Pdp/EcommerceBlock/PdpEcommerceBlockFitFinder.props'
import type {
  Category,
  SizeDetails,
} from '@design-system/components/Pdp/PdpFitFinder.props'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

const props = defineProps<PdpEcommerceBlockFitFinderProps>()
const emit = defineEmits<{
  onFitFinderClick: []
}>()

const { openDialog, closeDialog } = useDialog(
  SIDEBAR_IDS.pdpEcommerceBlockFitFinder
)

const {
  sizeGridData: sizeData,
  productCategory,
  countries,
} = await useFitFinder(props.sizeCodeId ?? '')

const { emitSizebayProductViewEvent } = useSizebayEvents()

const handlePrimaryClick = () => {
  closeDialog()
}

const handleFitFinderClick = () => {
  openDialog()
  emit('onFitFinderClick')
  emitSizebayProductViewEvent(props.productId, props.productTitle)
}
</script>
<template>
  <AsideButton
    class="border-t-0"
    data-testid="fit-finder"
    :aria-label="$ts('accessibility.openFitFinder')"
    @click="handleFitFinderClick"
  >
    <p class="text-medium-6">
      {{ $ts('pdp.fitFinder.label') }}
    </p>
  </AsideButton>

  <OrganismsSidebarSlide
    v-if="!hideSidebar"
    :id="SIDEBAR_IDS.pdpEcommerceBlockFitFinder"
    :header-props="{
      titleText: $ts('pdp.fitFinder.label'),
    }"
    @primary-click="handlePrimaryClick"
  >
    <template #body>
      <PdpFitFinder
        :category="productCategory as unknown as Category"
        :countries="countries"
        :sizes-details="sizeData as unknown as SizeDetails[]"
        :side-slide-description="props.sideSlideDescription"
      />
    </template>
  </OrganismsSidebarSlide>
</template>
