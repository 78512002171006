import type { z } from 'zod'

type ProductSchemaType = z.infer<typeof algoliaProductsSchema>

import ViSearch, {
  type Product,
  type ProductSearchResponse,
  type ProductSearchResponseError,
} from 'visearch-javascript-sdk'

export const useVisenze = () => {
  const { currentVisenzeConfig } = useAppConfig()

  const sharedAttrs = [
    'product_url',
    'size_list',
    'title',
    'brand',
    'additional_image_url',
    'price_amount',
    'tags',
    'alternative_color',
    'color',
    'price',
  ]

  const onErrorHandler = (error: ProductSearchResponseError) => {
    console.log(error)
  }

  const completeTheLook = async (
    sku: string
  ): Promise<Array<ProductSchemaType>> => {
    let data: Array<Product> = []

    if (currentVisenzeConfig?.app_key && currentVisenzeConfig.pdp_stl) {
      const visearch = ViSearch({
        app_key: currentVisenzeConfig?.app_key,
        placement_id: currentVisenzeConfig.pdp_stl,
      })

      const onResponse = (response: ProductSearchResponse) => {
        if (response.status === 'OK' && Array.isArray(response.result)) {
          data = response.result
        }
      }

      await visearch.productRecommendations(
        sku,
        {
          attrs_to_get: sharedAttrs,
          limit: 3,
        },
        onResponse,
        onErrorHandler
      )
    }

    return data ?? []
  }

  const similarProducts = async (
    sku: string
  ): Promise<Array<ProductSchemaType>> => {
    let data: Array<Product> = []

    if (currentVisenzeConfig?.app_key && currentVisenzeConfig?.pdp_vsr) {
      const visearch = ViSearch({
        app_key: currentVisenzeConfig?.app_key,
        placement_id: currentVisenzeConfig.pdp_vsr,
      })

      const onResponse = (response: ProductSearchResponse) => {
        if (response.status === 'OK' && Array.isArray(response.result)) {
          data = response.result
        }
      }

      await visearch.productRecommendations(
        sku,
        {
          attrs_to_get: sharedAttrs,
        },
        onResponse,
        onErrorHandler
      )
    }

    return data ?? []
  }

  return {
    completeTheLook,
    similarProducts,
  }
}
