export const usePaypalScript = () => {
  const config = useRuntimeConfig()
  const clientId = config.public.paypal.clientId

  if (!clientId) return false

  return useScript({
    src: `https://www.paypal.com/sdk/js?client-id=${clientId}&components=messages,buttons`,
    'data-namespace': 'PayPalSDK',
    key: 'Paypal',
    // type: 'text/partytown',
    defer: true,
  })
}
