<script setup lang="ts">
import { iconsMapAtomsMessage } from './AtomsMessageInfo.iconsMapping'
import type { AtomsMessageInfoProps } from './AtomsMessageInfo.props'

defineProps<AtomsMessageInfoProps>()
</script>

<template>
  <div class="gap-xxs flex items-center">
    <component
      :is="iconsMapAtomsMessage[icon]"
      v-if="icon"
      class="h-4 w-4"
      aria-hidden="true"
    />
    <p class="text-book-8">{{ text }}</p>
    <div class="gap-xxs flex flex-nowrap items-center">
      <slot name="dynamicContent" />
    </div>
  </div>
</template>
