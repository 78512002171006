<script setup lang="ts">
import type { KlarnaPlacementWrapperProps } from './KlarnaPlacementWrapper.props'

const props = defineProps<KlarnaPlacementWrapperProps>()

const appConfig = useAppConfig()
const enabled = computed(
  () =>
    !appConfig.currentMarketSettings.klarnaBNPLMaxPriceCents ||
    !props.placement.purchaseAmount ||
    props.placement.purchaseAmount <
      appConfig.currentMarketSettings.klarnaBNPLMaxPriceCents
)
// load script only if enabled
const script = enabled.value && useKlarnaScript()
const loaded = computed(() => script && script.status.value === 'loaded')

const { language, country } = useRouteHelper()

const locale = computed(() => {
  const locale = `${language.toLowerCase()}-${country.toUpperCase()}`
  const supportedLocales = [
    'en-AT',
    'de-AT',
    'nl-BE',
    'en-BE',
    'fr-BE',
    'en-CH',
    'de-CH',
    'it-CH',
    'fr-CH',
    'en-CZ',
    'cs-CZ',
    'de-DE',
    'en-DE',
    'da-DK',
    'en-DK',
    'es-ES',
    'en-ES',
    'fi-FI',
    'sv-FI',
    'en-FI',
    'fr-FR',
    'en-FR',
    'en-GB',
    'en-GR',
    'el-GR',
    'en-HU',
    'en-IE',
    'en-IT',
    'hu-HU',
    'it-IT',
    'nl-NL',
    'en-NL',
    'no-NO',
    'nb-NO',
    'en-NO',
    'en-PL',
    'pl-PL',
    'en-PT',
    'pt-PT',
    'en-RO',
    'ro-RO',
    'sv-SE',
    'en-SE',
    'sk-SK',
    'en-SK',
    'en-CA',
    'fr-CA',
    'es-MX',
    'en-MX',
    'en-US',
    'es-US',
    'en-AU',
    'en-NZ',
  ]
  if (supportedLocales.includes(locale)) return locale

  const fallbackLocale = supportedLocales.find(locale =>
    locale.startsWith(language.toLowerCase())
  )
  if (fallbackLocale) return fallbackLocale

  return 'en-US'
})
</script>

<template>
  <klarna-placement
    v-if="loaded"
    id="klarna-placement"
    :data-key="placement.key"
    :data-locale="locale"
    :data-purchase-amount="placement.purchaseAmount"
  ></klarna-placement>
</template>

<style>
/* https://docs.klarna.com/conversion-boosters/on-site-messaging/additional-resources/styling-on-site-messaging-with-css/ */
#klarna-placement::part(osm-message),
#klarna-placement::part(osm-cta) {
  font-size: 10px;
}
</style>
