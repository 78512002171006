import type { SkuSize } from '@design-system/components/Pdp/EcommerceBlock/Product/PdpEcommerceBlockProductDetails.props'
import type { extSize } from '@design-system/components/Pdp/PdpSizeSelector.props'

export const usePdpExtSizesUtils = () => {
  // get main product data
  const { productDataState: mainProductData, fetchAndMapProductData } =
    useTemplatePdp()

  const outOfStock = (avEcom: number, avRet: number) => {
    return avEcom + avRet === 0
  }
  const lastInStock = (avEcom: number, avRet: number) => {
    return avEcom + avRet === 1
  }

  const getDims = (
    selectedExtSku: string,
    extSizes: extSize[],
    thisProductDims: string,
    productRoute: string,
    sizes: SkuSize[] = [],
    oneSize: boolean = false
  ) => {
    if (oneSize) return sizes[0].Dims
    if (selectedExtSku !== productRoute) {
      const myObj = extSizes?.find((obj: extSize) => obj.SKU === selectedExtSku)
      return myObj?.Dims
    } else {
      return thisProductDims
    }
  }

  const imageId = (str: string) => {
    if (!str) return ''
    const firstChar = str.charAt(0)
    if (firstChar === firstChar.toUpperCase()) {
      return str
    } else {
      const arr = str.split('/')
      return arr[arr.length - 1]
    }
  }

  const transformSku = (input: string | undefined) => {
    if (!input) {
      return ''
    }
    const parts = input.split('_')
    const transformed = parts.slice(0, 3).join('_')
    return transformed
  }

  const thisProductDims = ref('')

  const renderExtSizesFormatted = async (
    prod: string,
    orderedGallery: TResponsiveGallery
  ) => {
    if (!mainProductData.value) return []

    const {
      price: firstPrice,
      oldPrice: firstOldPrice,
      discount: firstDiscount,
      sizes: firstSkuSizes,
      extSizes: firstExtSizes,
      computedLists: firstComputedLists,
      productPath: firstSkuPath,
    } = mainProductData.value

    const sizeImage = orderedGallery?.filter(
      (item: any) => item.type === 'TResponsiveImage'
    )
    thisProductDims.value = firstSkuSizes[0].Dims ?? ''

    const res: extSize[] = [
      {
        Dims: firstSkuSizes[0].Dims,
        SKU: transformSku(firstSkuSizes[0].SKU),
        skuWithSize: firstSkuSizes[0].SKU,
        avEcom: firstSkuSizes[0].avEcom,
        avRet: firstSkuSizes[0].avRet,
        image: sizeImage ? (sizeImage[0].data as string) : '',
        label: firstSkuSizes[0].label,
        extPrice: firstPrice,
        extOldPrice: firstOldPrice,
        extDiscount: firstDiscount,
        outOfStock:
          outOfStock(
            firstSkuSizes[0].avEcom ?? 0,
            firstSkuSizes[0].avRet ?? 0
          ) || firstComputedLists.sizeList[0].disabled,
        lastInStock:
          lastInStock(
            firstSkuSizes[0].avEcom ?? 0,
            firstSkuSizes[0].avRet ?? 0
          ) || firstComputedLists.sizeList[0].lastInStock,
        path: firstSkuPath,
      },
    ]

    const selectedSizeState = useState<extSize>('selectedSizeState')
    if (!selectedSizeState.value.SKU) selectedSizeState.value = res[0]

    // Get dims of main prod, push ext sizes elements, and sort
    if (firstExtSizes && firstExtSizes?.length > 0) {
      // Use Promise.all for parallel fetching
      const fetchPromises = firstExtSizes.map(async size => {
        const sku = transformSku(size.SKU)
        const extSkuProductData = await fetchAndMapProductData(sku)

        if (!extSkuProductData) {
          console.error('Error fetching product data for extSize SKU:', sku)
          return null
        }

        const {
          price: extPriceVal,
          oldPrice: extOldPrice,
          discount: extDiscount,
          extSizes: extExtSizes,
          sizes: extTUSizes,
          computedLists: extComputedLists,
          productPath: extSkuPath,
        } = extSkuProductData

        if (
          (res[0].Dims === '' || res[0].Dims === undefined) &&
          sku !== transformSku(firstSkuSizes[0].SKU)
        ) {
          const thisSize = extExtSizes?.find(
            obj => transformSku(obj.SKU) === prod
          )
          res[0].Dims = thisSize?.Dims
          thisProductDims.value = thisSize?.Dims ?? ''
        }

        const extAvEcom = extTUSizes[0].avEcom ?? 0
        const extAvRet = extTUSizes[0].avRet ?? 0

        return {
          Dims: size.Dims,
          SKU: sku,
          skuWithSize: size.SKU,
          avEcom: extAvEcom,
          avRet: extAvRet,
          image: imageId(size.image as string),
          label: size.label,
          extPrice: extPriceVal,
          extOldPrice: extOldPrice,
          extDiscount: extDiscount,
          outOfStock:
            outOfStock(extAvEcom, extAvRet) ||
            extComputedLists.sizeList[0].disabled,
          lastInStock:
            lastInStock(extAvEcom, extAvRet) ||
            extComputedLists.sizeList[0].lastInStock,
          path: extSkuPath,
        }
      })

      const fetchedData = await Promise.all(fetchPromises)
      res.push(...fetchedData.filter(data => data !== null))

      res.sort((a, b) => {
        if (!a.Dims || !b.Dims) {
          return 0
        }
        return a.Dims.localeCompare(b.Dims)
      })

      // Add index for size index value
      res.forEach((item, index) => {
        item.value = index
      })

      return res
    } else {
      return []
    }
  }

  const productDims = computed(() => thisProductDims.value)

  return {
    outOfStock,
    getDims,
    transformSku,
    imageId,
    renderExtSizesFormatted,
    productDims,
  }
}
