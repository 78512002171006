<script setup lang="ts">
import type { PdpEcommerceBlockSizeSelectorProps } from './PdpEcommerceBlockSizeSelector.props'
import type { extSize } from '../PdpSizeSelector.props'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'
const { ts } = useI18n()

const props = defineProps<PdpEcommerceBlockSizeSelectorProps>()
const emit = defineEmits<{
  'update:selectedSizeIndex': [selectedSizeIndex: number | null]
  'update:selectedCountry': [selectedCountry: string]
  'update:selectedExtSku': [selectedExtSku: string]
  'similar-products': []
  'sidebar-closed-after-leave': []
  sendAddToCart: []
}>()

const { isLoading } = useAddToCart()
const { openDialog, closeDialog } = useDialog()

const currentSize = ref<number | null>(null)
const isNotSelectedSize = ref(false)

const selectedSizeState = useState<extSize>('selectedSizeState')

const handlePrimaryClick = async () => {
  // one size
  if (props.isOneSize && (props.extSizes?.length === 0 || !props.extSizes))
    currentSize.value = 0
  // ext sizes
  if (props.extSizes && props.extSizes.length > 0) {
    currentSize.value = selectedSizeState?.value?.value ?? 0
    handleSidebarClosed()
  }
  // normal sizes, one sizes, ext sizes
  if (currentSize.value !== null) {
    isNotSelectedSize.value = false
    emit('update:selectedSizeIndex', currentSize.value)
    emit('sendAddToCart')
  } else {
    isNotSelectedSize.value = true
  }
}

const selectedSize = computed(() => {
  // with extSizes
  if (props.extSizes && props.extSizes.length > 0) {
    return selectedSizeState?.value?.label ?? ''
  } else {
    // without extSizes
    return (
      props.selectedSizeIndex !== null &&
      props.sizes[props.selectedCountry][props.selectedSizeIndex].size
    )
  }
})

const openDialogIfNotDisabled = (id: string) => {
  if (!props.selectedDefaultOrOneSizeOutOfStockCl && !props.isOneSize) {
    openDialog(id)
  }
}

const emitSimilarProducts = (id: string) => {
  closeDialog(id)
  emit('similar-products')
}

const handleSidebarClosed = () => {
  // page url is updated when the size selector sidebar has been closed. But the data is already fetched on selection.

  if (props.selectedExtSku && !!props.extSizes?.length) {
    const selectedExtSkuPath = props.extSizes.find(
      (obj: extSize) => obj.SKU === props.selectedExtSku
    )?.path
    // window.history.replaceState() has been used here instead navigateTo() to avoid refetching the same data
    window.history.replaceState(null, '', selectedExtSkuPath)
  }
}

const updateSizeFn = async (event: number | null) => {
  currentSize.value = event
  emit('update:selectedSizeIndex', currentSize.value)
}

watch(
  () => props.selectedSizeIndex,
  newVal => {
    currentSize.value = newVal
  }
)

const sizeLabel = computed(() => {
  let res = ''
  if (selectedSize.value) {
    res =
      props.isOneSize && (!props.extSizes || props.extSizes.length === 0)
        ? ts('pdp.sizeSelector.oneSize')
        : `${selectedSize.value} (${props.selectedCountry})`
  } else {
    res = ts('select')
  }
  return res
})
</script>

<template>
  <AsideButton
    data-testid="size-selector"
    :class="{
      'h-[100px]': isOneSize && !dims,
      'cursor-not-allowed': selectedDefaultOrOneSizeOutOfStockCl || isOneSize,
    }"
    :hide-arrow="isOneSize"
    :aria-label="$ts('accessibility.openSizeSelector')"
    @click="openDialogIfNotDisabled(SIDEBAR_IDS.pdpEcommerceBlockSizeSelector)"
  >
    <span>
      <span class="text-medium-6">{{ $ts('pdp.sizeSelector.size') }}: </span>
      <span class="text-light-6">
        {{ sizeLabel }}
      </span>
    </span>
  </AsideButton>
  <OrganismsSidebarSlide
    v-if="!hideSidebarComponent"
    :id="SIDEBAR_IDS.pdpEcommerceBlockSizeSelector"
    :header-props="{
      titleText: $ts('pdp.sizeSelector.selectSize'),
    }"
    :footer-props="{
      primaryText: $ts('addToBag'),
      isPrimaryLoading: isLoading,
    }"
    :selected-ext-out-of-stock="selectedExtOutOfStock"
    :selected-default-or-one-size-out-of-stock-cl="
      selectedDefaultOrOneSizeOutOfStockCl
    "
    @primary-click="handlePrimaryClick"
    @sidebar-closed="handleSidebarClosed"
    @sidebar-closed-after-leave="$emit('sidebar-closed-after-leave')"
  >
    <template #body>
      <div
        v-if="!extSizes || extSizes.length === 0"
        class="text-primitives-grey-200 text-book-6 md:text-book-5 mb-4"
      >
        {{ sideSlideDescription }}
      </div>
      <p
        v-if="isNotSelectedSize"
        role="alert"
        aria-live="assertive"
        class="text-book-6 text-primitives-red"
      >
        {{ $ts('pdp.sizeSelector.selectSizeError') }}
      </p>

      <PdpSizeSelector
        :sizes="sizes"
        :ext-sizes="extSizes"
        :selected-size-index="currentSize"
        :selected-country="selectedCountry"
        :selected-size="selectedSize"
        :is-one-size="isOneSize"
        :sku-sizes="skuSizes"
        @update:selected-size-index="updateSizeFn($event)"
        @update:selected-country="emit('update:selectedCountry', $event)"
        @update:selected-ext-sku="emit('update:selectedExtSku', $event)"
        @similar-products="
          emitSimilarProducts(SIDEBAR_IDS.pdpEcommerceBlockSizeSelector)
        "
      />
    </template>
  </OrganismsSidebarSlide>
</template>
