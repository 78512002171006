<script setup lang="ts">
import type { AddToCartButtonProps } from './PdpEcommerceBlockProductAddToCartButton.props'
const props = defineProps<AddToCartButtonProps>()
const emit = defineEmits<{
  'update:selectedSizeIndex': [selectedSizeIndex: number | null]
  'update:selectedCountry': [selectedCountry: string]
  sendAddToCart: []
}>()
const handleClick = async () => {
  if (props.isOneSize) {
    await emit('update:selectedSizeIndex', 0)
    await emit('update:selectedCountry', props.selectedCountry ?? 'IT')
  }
  emit('sendAddToCart')
}
</script>

<template>
  <MoleculesAddToBag
    class="w-auto"
    :square="true"
    :loading="isLoading"
    :disabled="
      disabled ||
      outOfStock ||
      selectedExtOutOfStock ||
      selectedDefaultOrOneSizeOutOfStockCl
    "
    data-testid="desktop-add-to-cart"
    data-tracking="BTN_ADD_TO_CART"
    @click-handler="handleClick"
  />
</template>
