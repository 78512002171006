<script setup lang="ts">
const app = useNuxtApp()
const isProduct = app.payload['is_product'] === true
console.log('slug page')
</script>

<template>
  <div>
    <ProductPage v-if="isProduct" />
    <HierarchyPage v-else />
  </div>
</template>
