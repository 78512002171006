<script setup lang="ts">
import type { PaypalWrapperProps } from './PaypalWrapper.props'

const props = withDefaults(defineProps<PaypalWrapperProps>(), {
  placement: 'product',
  styleTextSize: '10',
})

const appConfig = useAppConfig()
const { isProduction } = useRuntimeConfig().public
const { country } = useRouteHelper()

const enabled = computed(
  () =>
    !appConfig.currentMarketSettings.paypalBNPLMaxPriceCents ||
    props.amount < appConfig.currentMarketSettings.paypalBNPLMaxPriceCents
)

// load script only if enabled
const script = enabled.value && usePaypalScript()
const loaded = computed(() => script && script.status.value === 'loaded')

const paypalAmount = computed(() => (props.amount / 100).toFixed(2))

// https://developer.paypal.com/limited-release/sdk-pay-later-messaging-cross-border/#link-buyercountry
const paypalCountry = computed(() => {
  const paypalCountry = country.toUpperCase()
  const supportedCountries = ['AU', 'DE', 'ES', 'FR', 'GB', 'IT', 'US']
  const isSupported = supportedCountries.includes(paypalCountry)
  return isSupported ? paypalCountry : undefined
})
</script>

<template>
  <div
    v-if="loaded"
    data-pp-message
    :data-pp-placement="placement"
    :data-pp-amount="paypalAmount"
    :data-pp-style-text-size="styleTextSize"
    v-bind="isProduction ? {} : { 'data-pp-buyercountry': paypalCountry }"
  />
</template>
