<script setup lang="ts">
import type { UtilsinfoProps } from './UtilsInfo.props'

const { content, expandedLabel, reducedLabel, maxCharacter } =
  defineProps<UtilsinfoProps>()

const isExpanded = ref(false)

const showCta = computed(() => content.length > maxCharacter)

const toggleCta = () => {
  isExpanded.value = !isExpanded.value
}
</script>

<template>
  <div class="gap-xs flex flex-col items-start">
    <div
      class="text-light-6"
      :class="{ 'line-clamp-3': !isExpanded && showCta }"
    >
      <p>{{ content }}</p>
    </div>
    <AtomsCta v-if="showCta" anatomy="link" @click="toggleCta">
      <template #label>
        {{ isExpanded ? reducedLabel : expandedLabel }}
      </template>
    </AtomsCta>
  </div>
</template>
