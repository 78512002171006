<script setup lang="ts">
import type { PdpEcommerceBlockReserveInStoreStepThreeProps } from './PdpEcommerceBlockReserveInStoreStepThree.props'
defineProps<PdpEcommerceBlockReserveInStoreStepThreeProps>()
const emit = defineEmits<{
  seeDetailsClick: [storeCode: string]
}>()
</script>

<template>
  <div class="flex flex-col gap-10 pb-6">
    <div class="flex flex-col gap-6">
      <p class="text-book-6 uppercase">
        {{ $ts('pdp.reserveInStore.thankYou.selectedStore') }}
      </p>
      <PdpEcommerceBlockReserveInStoreShopAddress
        :shop="shop"
        @see-details-click="emit('seeDetailsClick', $event)"
      />
    </div>
    <div class="flex flex-col gap-6">
      <p class="text-book-6 uppercase">
        {{ $ts('pdp.reserveInStore.personalInfo.title') }}
      </p>
      <div class="text-light-6 flex flex-col gap-1">
        <p class="text-medium-5">
          {{ formPersonalInfo.firstName }} {{ formPersonalInfo.lastName }}
        </p>
        <div>
          <p>{{ formPersonalInfo.email }}</p>
          <p v-if="formPersonalInfo.phoneNumber">
            {{ formPersonalInfo.phonePrefix }}
            {{ formPersonalInfo.phoneNumber }}
          </p>
        </div>
        <p>
          {{ formPersonalInfo.message }}
        </p>
      </div>
    </div>
  </div>
</template>
