<script setup lang="ts">
import type { ReserveShop } from '@design-system/components/Pdp/EcommerceBlock/ReserveInStore/PdpEcommerceBlockReserveInStoreShopAddress.props'
import type { PdpEcommerceBlockReserveInStoreStepOneProps } from './PdpEcommerceBlockReserveInStoreStepOne.props'

const props = defineProps<PdpEcommerceBlockReserveInStoreStepOneProps>()

defineEmits<{
  seeDetailsClick: [storeCode: string]
}>()

const { ts } = useI18n()
const selectedStoreModel = defineModel<ReserveShop | undefined>(undefined)

const onChangeTab = () => {
  selectedStoreModel.value = undefined
}

type Tab = {
  id: string
  label: string
  shops: ReserveShop[]
}

const rawTabs = ref<Tab[]>([
  {
    id: props.country,
    label: ts(`countriesList.${props.country}`),
    shops: [],
  },
  {
    id: 'row',
    label: ts('pdp.reserveInStore.selectStoreTabsLabel.restOfTheWorld'),
    shops: [],
  },
])

const filledTabs = computed(() => {
  if (props.shops && props.shops.length > 0) {
    for (let i = 0; i < props.shops.length; i++) {
      if (props.shops[i].address.country === props.country.toUpperCase()) {
        rawTabs.value[0].shops.push(props.shops[i])
      } else {
        rawTabs.value[1].shops.push(props.shops[i])
      }
    }
  }
  return rawTabs.value
})

const selectedTabIndex = useState('reserve-in-store-selected-tab', () => 0)

// Composables needed to calculate move and select the right shortcut and handle the transition
const { slider, handleShortcutClick, updateSliderPosition } =
  useSliderTransition(selectedTabIndex.value, false)

onMounted(() => {
  updateSliderPosition(selectedTabIndex.value)
  window.addEventListener('resize', handleResize)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})

const handleResize = () => {
  updateSliderPosition(selectedTabIndex.value)
}
</script>

<template>
  <div class="mt-2">
    <UtilsTabs
      v-model="selectedTabIndex"
      class-buttons-container="flex slider-tab"
      :tabs="filledTabs"
      @change="onChangeTab"
    >
      <template #button="{ tab, selected, index }">
        <UITabElement
          class="shortcut-element flex-1 flex-grow"
          :class="{ 'z-10': selected }"
          :selected="selected"
          :shortcut-index="index"
          @click="handleShortcutClick(index)"
        >
          {{ tab.label }}
        </UITabElement>
      </template>
      <template #tabs-sufix>
        <div ref="slider" class="slider-tab-border" />
      </template>

      <template #panel="{ tab }">
        <div v-if="(tab as Tab).shops?.length">
          <div v-if="tab.id === 'row'" class="pt-10">
            <p class="text-book-6">
              {{ $ts('pdp.reserveInStore.disclaimerRestOfTheWorld') }}
            </p>
          </div>
          <div class="pt-10">
            <h3 class="text-book-5 uppercase">
              {{ $ts('pdp.reserveInStore.selectAStore') }}
            </h3>
            <p
              v-if="props.isNotSelectedStore"
              class="text-book-6 text-primitives-red"
              role="alert"
              aria-live="assertive"
            >
              {{ $ts('pdp.reserveInStore.selectStoreError') }}
            </p>
            <div
              v-for="shop in (tab as Tab).shops"
              :key="shop.code"
              class="bg-background-off mt-4"
            >
              <InputsRadioWithSlot
                :id="shop.code"
                v-model="selectedStoreModel"
                :name="`${shop.address.country}_store`"
                :value="shop"
                :label="shop.name"
                is-always-expanded
                :custom-class="'bg-transparent'"
              >
                <template #extra-content>
                  <PdpEcommerceBlockReserveInStoreShopAddress
                    :shop="shop"
                    :show-shop-name="false"
                    :checkbox-padding="true"
                    @see-details-click="$emit('seeDetailsClick', $event)"
                  />
                </template>
              </InputsRadioWithSlot>
            </div>
          </div>
        </div>
        <div
          v-else
          :class="[
            props.isNotSelectedStore ? 'text-primitives-red pt-10' : 'pt-10',
          ]"
          role="alert"
          aria-live="assertive"
        >
          <p>{{ $ts('pdp.reserveInStore.noStoreFound') }}</p>
        </div>
      </template>
    </UtilsTabs>
  </div>
</template>
