<script setup lang="ts">
import type { PdpImage3dIframeProps } from './PdpImage3dIframe.props'

const props = defineProps<PdpImage3dIframeProps>()

const { country, countryAndLanguage } = useRouteHelper()
const { getCurrencyCodeFromCountryCode } = useCurrency()
const currencyCode = getCurrencyCodeFromCountryCode(country)

const getIframeSrc = (model: string) => {
  //model remove '_Q'
  const modelParsed = model.replace('_Q', '')
  // https://custom.3dandarviewer.com/armani
  return `https://custom.3dandarviewer.com/armani?name=${modelParsed}&dam=true&modelCode=${modelParsed}&currency=${currencyCode}&ecommerce=viewer&qty=1&culture=${countryAndLanguage}&userID=261556&fromUrl=1&nozoom=0&nofullscreen=0&disableZoomScroll=1`
}

const iframeSrc = ref()

onMounted(() => {
  if (props.model) {
    iframeSrc.value = getIframeSrc(props.model)
  }
})
</script>
<template>
  <iframe
    :src="iframeSrc"
    width="100%"
    height="100%"
    class="m-0 flex-grow border-none p-0"
    loading="lazy"
    allow="fullscreen; accelerometer; magnetometer; autoplay; encrypted-media; gyroscope; picture-in-picture; camera *; xr-spatial-tracking;"
  ></iframe>
</template>
