export const useInfoReturn = async (
  deliveryKey: string,
  categoriesSlugs: string[]
) => {
  const localePath = useLocalePath()
  const { country } = useRouteHelper()

  const query = {
    locale: localePath(''),
    vse: useRoute().query.vse,
    contentId: useRoute().query.contentId,
    deliveryKey,
  }

  const { data } = await useFetch('/api/cms/getInfoReturn', {
    query,
  })

  const title = data.value?.content.title ?? ''

  const countries =
    data.value?.content.countries?.map(
      country => (country as unknown as { code: string }).code
    ) ?? []

  const hideInCountry = countries.includes(country)

  const categories =
    data.value?.content.categories
      ?.map(category => (category as unknown as { urlSlug: string }).urlSlug)
      .filter(isNonNullable) ?? []

  const hideInCategory = categories.some(item => categoriesSlugs.includes(item))

  return { title, hideInCountry, hideInCategory }
}
