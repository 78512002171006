<script setup lang="ts">
const ecommSliderEventBus = useEventBus<number>(
  'ecommerce-block-slider-event-bus'
)

const activeSlide = ref(0)
const counter = ref(0)

ecommSliderEventBus.on(intersectedSlide => {
  // swiperInstance.value.slideTo(intersectedSlide)
  activeSlide.value = intersectedSlide

  // this check fixes the bug of thumbnail slider that is not starting from the first slide/index
  if (counter.value === 0 && activeSlide.value !== 0 && window.scrollY < 100) {
    // swiperInstance.value.slideTo(0)
    activeSlide.value = 0
    counter.value++
  }
})

defineSlots<{
  default(props: { activeSlide: number }): any
}>()
</script>

<template>
  <slot :active-slide="activeSlide" />
</template>
