import type { PdpEcommerceBlockProductFocusSideSlideProps } from '@design-system/components/Pdp/EcommerceBlock/PdpEcommerceBlockProductFocusSideSlide.props'

export const useProductFocus = async (deliveryKeys: string[]) => {
  const nuxtApp = useNuxtApp()

  const localePath = await nuxtApp.runWithContext(() => useLocalePath())
  const route = await nuxtApp.runWithContext(() => useRoute())

  const { data } = await nuxtApp.runWithContext(
    async () =>
      await useAsyncData<PdpEcommerceBlockProductFocusSideSlideProps>(
        `product-focus-${deliveryKeys.join('_')}`,
        async () => {
          const items = await Promise.all(
            deliveryKeys.map(key =>
              $fetch('/api/cms/getProductFocusItem', {
                query: {
                  // @ts-ignore
                  locale: localePath(''),
                  // @ts-ignore
                  vse: route.query.vse,
                  // @ts-ignore
                  contentId: route.query.contentId,
                  deliveryKey: key.replaceAll(' ', '_'),
                },
              })
            )
          )
          return {
            items: items.map(item => ({
              image: {
                light: item?.content?.light?.icon?.secure_url ?? '',
                dark: item?.content?.dark?.icon?.secure_url ?? '',
                alt: item?.content.alt ?? '',
              },
              description: item?.content.description ?? '',
            })),
          }
        }
      )
  )

  return { data }
}
