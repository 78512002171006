export const useKlarnaScript = () => {
  const appConfig = useAppConfig()
  const clientId = appConfig.currentMarketSettings.klarnaPlacementKey

  if (!clientId) return false

  return useScript({
    defer: true,
    'data-environment': 'production',
    src: 'https://js.klarna.com/web-sdk/v1/klarna.js',
    'data-client-id': clientId,
    key: 'Klarna',
    // type: 'text/partytown',
  })
}
