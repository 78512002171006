import type {
  ProductCareItem,
  ProductCareItemList,
} from '@design-system/components/Pdp/EcommerceBlock/PdpEcommerceBlockProductInfo.props'

export const useProductCare = () => {
  const { productFeaturesList } = useProductFeaturesList('product-care-items')

  const productCareItems: ComputedRef<ProductCareItemList> = computed(() => ({
    title: productFeaturesList.value?.title ?? '',
    description: productFeaturesList.value?.description ?? '',
    // TODO: type this better
    items: (
      (productFeaturesList.value?.listOfContentItems as unknown as any[]) ?? []
    )
      .map(i => ({
        id: i.id,
        title: i.title ?? '',
        shortDescription: i.shortDescription,
        longDescription: i.longDescription,
        image: i.image?.secure_url,
      }))
      .filter((i): i is ProductCareItem => !!i.id),
  }))

  const getProductCareItemsByID = (items?: string[]): ProductCareItemList => {
    return {
      ...productCareItems.value,
      items: productCareItems.value?.items.filter(
        i => items?.includes(i.id) ?? false
      ),
    }
  }

  return {
    data: productCareItems,
    getProductCareItemsByID,
  }
}
