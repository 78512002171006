<script setup lang="ts">
import type { PdpEcommerceBlockReserveInStoreShopAddressProps } from './PdpEcommerceBlockReserveInStoreShopAddress.props'
withDefaults(defineProps<PdpEcommerceBlockReserveInStoreShopAddressProps>(), {
  showShopName: true,
  checkboxPadding: false,
})
const emit = defineEmits<{
  seeDetailsClick: [storeCode: string]
}>()
</script>

<template>
  <div>
    <p v-if="showShopName" class="text-medium-5">{{ shop.name }}</p>
    <div :class="{ 'pl-7': checkboxPadding }">
      <p class="text-ligth-6">{{ shop.address.line1 }}</p>
      <p class="text-ligth-6">
        {{ shop.address.postalCode }} {{ shop.address.city }},
        {{ shop.address.state }},
        {{ shop.address.country }}
      </p>
      <AtomsCta
        anatomy="link"
        aria-haspopup="dialog"
        @click.stop="emit('seeDetailsClick', shop.code)"
      >
        <template #label>
          {{ $ts('pdp.reserveInStore.seeStoreDetailsLink') }}
        </template>
      </AtomsCta>
    </div>
  </div>
</template>
